
// Components
import VImageSrcSet from '@@/common/components/ui/images/VImageSrcSet';
import VTag from 'portal/components/ui/tag/VTag';

const statuses = {
    ONLILNE: 'Онлайн',
    OFLINE: 'Офлайн',
};

export default {
    name: 'EventCard',

    components: {
        VTag,
        VImageSrcSet,
    },

    props: {
        event: {
            type: Object,
            default: () => ({}),
        },

        /**
         * Пропс, определяющий, нужно ли отображать изображение на мобилке
         */
        showImage: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return [
                {
                    [this.$style._showImage]: this.showImage,
                },
            ];
        },

        status() {
            return this.event?.online ? statuses.ONLILNE : statuses.OFLINE;
        },

        isVisibleTag() {
            return this.$deviceIs.pc;
        },
    },
};
