
// Helpers
import { chunkArray } from 'portal/assets/js/helpers/helpers';
// Components
import EventCard from 'portal/components/common/events/EventCard';
import VButtonNew from 'portal/components/ui/button/VButtonNew';
import VSwiper from 'portal/components/ui/swiper/VSwiper';

const titleText = {
    desktop: 'События',
    mobile: 'Ближайшие события',
};

const breakpoints = {
    320: {
        slidesPerView: 1,
        spaceBetween: 12,
    },

    768: {
        slidesPerView: 3,
        spaceBetween: 20,
    },
};

export default {
    name: 'HomeEvents',
    components: {
        VSwiper,
        VButtonNew,
        EventCard,
    },

    props: {
        events: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            slider: null,
        };
    },

    computed: {
        isVisiblePagination() {
            return !this.$deviceIs.pc && !this.$deviceIs.tablet;
        },

        slides() {
            if (this.isVisiblePagination) {
                return chunkArray(this.events, 2);
            }

            return chunkArray(this.events, 1);
        },

        customSwiperOptions() {
            return {
                breakpoints,
            };
        },

        title() {
            return this.$deviceIs.pc ? titleText.desktop : titleText.mobile;
        },
    },

    methods: {
        handleNextSlide() {
            this.$refs.slider.onNext();
        },

        handlePrevSlide() {
            this.$refs.slider.onPrev();
        },
    },
};
