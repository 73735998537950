// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeEvents_cToYN{width:100%}.top_PROz4{align-items:center;display:grid;grid-template-columns:-webkit-max-content -webkit-max-content;grid-template-columns:max-content max-content;justify-content:space-between;margin-bottom:4rem}@media(max-width:767.98px){.top_PROz4{margin-bottom:2.2rem}}.buttons_5Izo6{display:grid;gap:1rem;grid-template-columns:4rem 4rem;grid-template-rows:4rem}@media(max-width:767.98px){.buttons_5Izo6{display:none}}.title_MUj3x{font-size:3.2rem;font-weight:500;letter-spacing:-.128rem;line-height:120%;text-transform:unset}@media(max-width:767.98px){.title_MUj3x{font-size:2.8rem;font-weight:500;letter-spacing:-.04em;line-height:1.1em}.slider_fAYPu{margin-left:-3.2rem;margin-left:-2rem;padding:0 3.2rem;padding:0 2rem;width:calc(100% + 6.4rem);width:calc(100% + 4rem)}.wrapper_jRYiP{display:flex;gap:unset}}.event_wtCFA{height:100%;width:100%}.pagination_BFnon{margin-top:1.6rem}@media(max-width:767.98px){.button_rHi-U{width:100%}}.buttonNavigation_IaZ0K.v-button{height:auto;padding:0}.buttonNavigation_IaZ0K.v-button:last-child{transform:rotate(180deg)}@media(max-width:767.98px){.swiper_aCEqc.swiper .swiper-wrapper{padding-bottom:2rem}}.swiper_aCEqc.swiper .swiper-wrapper .swiper-slide{display:grid;gap:1.6rem;grid-template-rows:1fr;height:auto}@media(max-width:767.98px){.swiper_aCEqc.swiper .swiper-wrapper .swiper-slide{gap:1.2rem;grid-template-rows:1fr 1fr}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"HomeEvents": "HomeEvents_cToYN",
	"top": "top_PROz4",
	"buttons": "buttons_5Izo6",
	"title": "title_MUj3x",
	"slider": "slider_fAYPu",
	"wrapper": "wrapper_jRYiP",
	"event": "event_wtCFA",
	"pagination": "pagination_BFnon",
	"button": "button_rHi-U",
	"buttonNavigation": "buttonNavigation_IaZ0K",
	"swiper": "swiper_aCEqc"
};
module.exports = ___CSS_LOADER_EXPORT___;
